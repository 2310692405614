import { render, staticRenderFns } from "./ReactionChip.vue?vue&type=template&id=52b8bacc&scoped=true"
import script from "./ReactionChip.vue?vue&type=script&lang=ts"
export * from "./ReactionChip.vue?vue&type=script&lang=ts"
import style0 from "./ReactionChip.vue?vue&type=style&index=0&id=52b8bacc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b8bacc",
  null
  
)

export default component.exports